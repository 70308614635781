import React, { useEffect, useState } from "react";

//react-redux
import { useSelector, useDispatch } from "react-redux";

//routing
import { Link } from "react-router-dom";

//dayjs
import dayjs from "dayjs";

import arraySort from "array-sort";
import {
  acceptHostReq,
  acceptHostRequest,
  getHostRequest,
} from "../../../store/hostRequest/action";
import Pagination from "../../../pages/Pagination";
import AcceptedRequest from "./AcceptedRequest";
import DeclineRequest from "./DeclineRequest";
import {
  OPEN_AGENCY_CODE_DIALOGUE,
  OPEN_REASON_DIALOGUE,
} from "../../../store/hostRequest/type";
import ReasonDialogue from "./ReasonDialogue";
import AddAgencyCodeDialogue from "./AddAgencyCodeDialogue";
import { Dialog, DialogContent, DialogTitle, IconButton, Tooltip } from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import { getAgency } from "../../../store/agency/action";
// import ReasonDialogue from "./ReasonDialogue";
// import AddAgencyCodeDialogue from "./AddAgencyCodeDialogue";

const PendingRequest = () => {
  const dispatch = useDispatch();
  const { request, total } = useSelector((state) => state.hostRequest);
  const { agency } = useSelector((state) => state?.agency);

  const [data, setData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [openAccept, setOpenAccept] = useState(false)
  const [reason, setReason] = useState("");
  const [errors, setErrors] = useState("");
  const [agencyCode, setAgencyCode] = useState();
  const [selectedRequest, setSelectedRequest] = useState(null);
  console.log("data", data);

  useEffect(() => {
    dispatch(getHostRequest(activePage, rowsPerPage, 1));
  }, [activePage, rowsPerPage, 1]);
  useEffect(() => {
    setData(request);
  }, [request]);
  useEffect(() => {
    dispatch(getAgency(1, 1000));
  }, [1, 1000]);
  //   pagination

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setActivePage(1);
    setRowsPerPage(value);
  };
  const validateDecline = () => {
    let error = {};
    let isValid = true;
    if (!reason || reason === "") {
      error.reason = "Please enter valid reason!";
      isValid = false;
    }
    setErrors(error);
    return isValid;
  };

  const handleDeclineSubmit = (type) => {
    if (validateDecline()) {
      dispatch(acceptHostReq(selectedRequest?._id, type, null, reason))
      setOpenDialogue(false);
      setReason("")
    }
  };
  const validateAccept = () => {
    let error = {};
    let isValid = true;
    if (!agencyCode || agencyCode === "Select Code" || agencyCode === "undefined") {
      error.agencyCode = "Please select an agency code!";
      isValid = false;
    }
    setErrors(error);
    return isValid;
  };

  const handleAcceptSubmit = (type) => {
    if (validateAccept()) {
      dispatch(acceptHostReq(selectedRequest?._id, type, agencyCode))
      setOpenAccept(false);
      setAgencyCode("")
    }
  };
  const handleDecline = (id, type) => {
    dispatch({ type: OPEN_REASON_DIALOGUE, payload: { id: id, type: type } });
  };

  return (
    <>
      <Dialog
        open={openDialogue}
        aria-labelledby="responsive-dialog-title"
        onClose={() => {
          setOpenDialogue(false);
          setReason("")
        }}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="responsive-dialog-title">
          <span className="text-danger font-weight-bold h4"> Add Reason </span>
        </DialogTitle>

        <IconButton
          style={{
            position: "absolute",
            right: 0,
          }}
        >
          <Tooltip title="Close">
            <Cancel className="text-danger" onClick={() => {
              setOpenDialogue(false);
              setReason("")
            }} />
          </Tooltip>
        </IconButton>
        <DialogContent>
          <div className="modal-body pt-1 px-1 pb-3">
            <div className="d-flex flex-column">
              <form>
                <div className="form-group">
                  <label className="mb-2 text-gray">Reason</label>
                  <input
                    type="text"
                    className="form-control"
                    required=""
                    placeholder="Enter Valid Reason"
                    value={reason}
                    onChange={(e) => {
                      setReason(e.target.value);

                      if (!e.target.value) {
                        return setErrors({
                          ...errors,
                          reason: "Reason is Required!",
                        });
                      } else {
                        return setErrors({
                          ...errors,
                          reason: "",
                        });
                      }
                    }}
                  />
                  {errors.reason && (
                    <div className="ml-2 mt-1">
                      {errors.reason && (
                        <div className="pl-1 text__left">
                          <span className="text-red">{errors.reason}</span>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="mt-5">
                  <button
                    type="button"
                    className="btn btn-outline-info ml-2 btn-round float__right icon_margin"
                    onClick={() => {
                      setOpenDialogue(false);
                      setReason("")
                    }}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-round float__right btn-danger"
                    onClick={() => {
                      handleDeclineSubmit("decline")
                    }}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openAccept}
        aria-labelledby="responsive-dialog-title"
        onClose={() => {
          setOpenAccept(false)
          setAgencyCode("")
        }}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        style={{ maxHeight: "600px", marginTop: "100px" }}
      >
        <DialogTitle id="responsive-dialog-title">
          <span className="text-danger font-weight-bold h4">Host Request Details</span>
        </DialogTitle>

        <IconButton
          style={{
            position: "absolute",
            right: 0,
          }}
        >
          <Tooltip title="Close">
            <Cancel className="text-danger" onClick={() => {
              setOpenAccept(false);
              setAgencyCode("")
            }} />
          </Tooltip>
        </IconButton>
        <DialogContent>
          <div className="modal-body pt-1 px-1 pb-3">
            <div className="d-flex flex-column">
              <form>
                <div class="form-group col-12 my-3">
                  <label className="mb-2 mt-2 text-gray">Select Agency Code</label>

                  <select
                    class="form-select form-control"
                    aria-label="Default select example"
                    disabled={selectedRequest?.agencyCode === null}
                    value={agencyCode}
                    onChange={(e) => {
                      setAgencyCode(e.target.value);
                      setErrors({ ...errors, agencyCode: "" })
                    }}
                  >
                    <option selected value="Select Code">
                      Select Code
                    </option>
                    {Array.isArray(agency) &&
                      agency.map((agencyItem) => {
                        return (
                          <option
                            key={agencyItem?.agencyCode}
                            value={agencyItem?.agencyCode}
                          >
                            {agencyItem?.agencyCode}
                          </option>
                        );
                      })}
                  </select>
                  {errors.agencyCode && (
                    <div className="ml-2 mt-1">
                      {errors.agencyCode && (
                        <div className="pl-1 text__left">
                          <span className="text-red">{errors.agencyCode}</span>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="mt-2">
                  <div style={{ display: "flex", justifyContent: "space-around" }}>
                    <div>
                      <label className="mb-2 ms-2 text-gray">Documents</label>
                      <img src={selectedRequest?.document} style={{ cursor: "pointer" }} onClick={() => { window.open(selectedRequest?.document) }} height={100} width={100} />
                    </div>
                    <div>
                      <label className="mb-2 ms-2 text-gray">Documents 2</label>
                      <img src={selectedRequest?.document2} style={{ cursor: "pointer" }} onClick={() => { window.open(selectedRequest?.document2) }} height={100} width={100} />
                    </div>
                    <div>
                      <label className="mb-2 ms-2 text-gray">Profile Image</label>
                      <img src={selectedRequest?.profileImage} style={{ cursor: "pointer" }} onClick={() => { window.open(selectedRequest?.profileImage) }} height={100} width={100} />
                    </div>
                  </div>
                </div>
                <div className="mt-5">
                  <button
                    type="button"
                    className="btn btn-outline-info ml-2 btn-round float__right icon_margin"
                    onClick={() => {
                      setOpenAccept(false);
                      setAgencyCode("")
                    }}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-round float__right btn-danger"
                    onClick={() => {
                      handleAcceptSubmit("accept")
                    }}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <div class="row">
        <div class="col">
          <div class="card">
            <div className="card-header pb-0">
              <div className="row my-3">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left"></div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right"></div>
              </div>
            </div>
            <div class="card-body card-overflow">
              <div class="d-sm-flex align-items-center justify-content-between mb-4"></div>

              <table class="table table-striped">
                <thead className="text-white">
                  <tr>
                    <th>No.</th>
                    <th>Image</th>
                    <th>User Name</th>
                    <th>Agency Code</th>
                    <th>CreatedAt</th>
                    <th>Accept</th>
                    <th>Decline</th>
                  </tr>
                </thead>
                <tbody className="t">
                  {data?.length > 0 ? (
                    data?.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <img
                              height="50px"
                              width="50px"
                              alt="app"
                              src={data?.user?.image}
                              style={{
                                boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                                border: "2px solid #fff",
                                borderRadius: 10,
                                objectFit: "cover",
                                display: "block",
                              }}
                              className="mx-auto"
                            />
                          </td>
                          <td>{data?.user?.name}</td>
                          <td>{data?.agencyCode ? data?.agencyCode : "-"}</td>

                          <td>
                            {dayjs(data?.createdAt).format("DD MMM, YYYY")}
                          </td>
                          <td>
                            <button
                              class="btn btn-sm text-white success"
                              onClick={() => {
                                setOpenAccept(true);
                                setSelectedRequest(data);
                              }
                              }
                            >
                              <i class="fa fa-check"></i> Accept
                            </button>
                          </td>
                          <td>
                            <button
                              class="btn btn-sm text-white danger"
                              onClick={() => {
                                setSelectedRequest(data);
                                setOpenDialogue(true)
                              }
                              }
                            >
                              <i class="fas fa-times"></i> Decline
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="10" align="center">
                        Nothing to show!!
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <Pagination
                activePage={activePage}
                rowsPerPage={rowsPerPage}
                userTotal={total}
                handleRowsPerPage={handleRowsPerPage}
                handlePageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
      <ReasonDialogue />
      <AddAgencyCodeDialogue />
    </>
  );
};

export default PendingRequest;
