import {
  CLOSE_SUB_ADMIN_DIALOGUE,
  CREATE_SUB_ADMIN,
  DELETE_SUB_ADMIN,
  GET_SUB_ADMIN,
  OPEN_SUB_ADMIN_DIALOGUE,
  UPDATE_SUB_ADMIN,
} from "./types";

const initialState = {
  subAdmin: [],
  total: 0,
  dialog: false,
  dialogData: null,
};

const subAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUB_ADMIN:
      return {
        ...state,
        subAdmin: action.payload,
      };
    case CREATE_SUB_ADMIN:
      let data = [...state.subAdmin];
      data?.unshift(action.payload);

      return {
        ...state,
        subAdmin: data,
      };
    case UPDATE_SUB_ADMIN:
      return {
        ...state,
        subAdmin: state.subAdmin.map((item) =>
          item?._id === action?.payload?.id ? action?.payload?.data : item
        ),
      };

    case DELETE_SUB_ADMIN:
      return {
        ...state,
        subAdmin: state.subAdmin.filter(
          (subAdmin) => subAdmin._id !== action?.payload
        ),
      };
    case OPEN_SUB_ADMIN_DIALOGUE:
      return {
        ...state,
        dialog: true,
        dialogData: action.payload || null,
      };
    case CLOSE_SUB_ADMIN_DIALOGUE:
      return {
        ...state,
        dialog: false,
        dialogData: null,
      };

    default:
      return state;
  }
};

export default subAdminReducer;
