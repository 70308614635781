import axios from "axios";
import { Toast } from "../../util/Toast";

import { apiInstanceFetch } from "../../util/api";
import {
  CREATE_SUB_ADMIN,
  DELETE_SUB_ADMIN,
  GET_SUB_ADMIN,
  UPDATE_SUB_ADMIN,
} from "./types";
import { toast } from "react-toastify";

export const getSubAdmin = () => (dispatch) => {
  apiInstanceFetch
    .get(`admin/getSubAdmin`)
    .then((res) => {
      if (res.status) {
        dispatch({ type: GET_SUB_ADMIN, payload: res.data });
      } else {
        Toast("error", res.message);
      }
    })
    .catch((error) => {
      console.log(error);
      Toast("error", error.message);
    });
};

export const addSubAdmin = (data) => (dispatch) => {
  axios
    .post(`/admin/roleAssignement`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: CREATE_SUB_ADMIN, payload: res.data.data });
        Toast("success", "Sub Admin Insert Successful");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const deleteSubAdmin = (id) => (dispatch) => {
  axios
    .delete(`admin/deleteSubAdmin?adminId=${id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: DELETE_SUB_ADMIN,
          payload: id,
        });
        toast("success", "Sub Admin delete Successfully");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => {
      console.log(error);
      Toast("error", error.message);
    });
};

export const updateSubAdmin = (data, id) => (dispatch) => {
  axios
    .patch(`admin/updateSubAdmin?adminId=${id}`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: UPDATE_SUB_ADMIN,
          payload: { data: res.data.admin, id: id },
        });
        Toast("success", "Sub Admin Update Successful");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};
