import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteSubAdmin, getSubAdmin } from "../../../store/subAdmin/action";
import { warning } from "../../../util/Alert";
import { OPEN_SUB_ADMIN_DIALOGUE } from "../../../store/subAdmin/types";
import SubAdminDialog from "../../dialog/SubAdminDialog";
import { Tooltip } from "antd";

const SubAdmin = () => {
  const dispatch = useDispatch();

  const { subAdmin } = useSelector((state) => state.subAdmin);

  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getSubAdmin());
  }, []);

  useEffect(() => {
    setData(subAdmin);
  }, [subAdmin]);

  const handleOpen = () => {
    dispatch({ type: OPEN_SUB_ADMIN_DIALOGUE });
  };

  const handleEdit = (data) => {
    dispatch({ type: OPEN_SUB_ADMIN_DIALOGUE, payload: data });
  };

  const handleDelete = (id) => {
    const data = warning();
    data
      .then((isDeleted) => {
        if (isDeleted) {
          dispatch(deleteSubAdmin(id));
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <div>
      <div class="row">
        <div class="col">
          <div class="card">
            <div className="card-header pb-0">
              <div className="row my-3">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left">
                  <button
                    type="button"
                    className="btn waves-effect waves-light btn-danger btn-sm float-left"
                    onClick={handleOpen}
                    id="bannerDialog"
                  >
                    <i className="fa fa-plus"></i>
                    <span className="icon_margin">New</span>
                  </button>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right"></div>
              </div>
            </div>
            <div class="card-body card-overflow">
              <div class="d-sm-flex align-items-center justify-content-between mb-4"></div>

              <table class="table table-striped">
                <thead className="text-white">
                  <tr>
                    <th>No.</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Password</th>
                    <th>Role</th>
                    <th>Edit</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody className="t">
                  {data?.length > 0 ? (
                    data?.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>

                          <td>{data?.name}</td>
                          <td>{data?.email}</td>
                          <td>{data?.password}</td>
                          <td>
                            {data?.roles.map((r) => {
                              return <span>{" " + r.name + " " + ","}</span>;
                            })}
                          </td>
                          <td>
                            <button
                              type="button"
                              className="btn btn-sm btn-info"
                              onClick={() => handleEdit(data)}
                            >
                              Edit
                            </button>
                          </td>
                          <td>
                            <button
                              type="button"
                              class="btn btn-danger"
                              onClick={() => handleDelete(data?._id)}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="10" align="center">
                        Nothing to show!!
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <SubAdminDialog />
    </div>
  );
};

export default SubAdmin;
